import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Link from "next/link";
import { BsCardImage } from "react-icons/bs";
import { BsClock } from "react-icons/bs";
import {BsCardText} from "react-icons/bs";

export default function Component() {
  return (
    <>
      <Row style={{ marginTop: 25 }}>
        <Col style={{ textAlign: "center" }}>
          <div className="d-grid gap-3">
            <Link href="/jobPhotoUpload">
              <Button
                size="lg"
                style={{ backgroundColor: "#A8560B", borderColor: "#A8560B" }}
              >
                <BsCardImage />
                &nbsp; Job Photo Upload
              </Button>
            </Link>
            {/* <Link href="/jobDetails">
              <Button
                size="lg"
                style={{ backgroundColor: "#A8560B", borderColor: "#A8560B" }}
              >
                <BsCardText />
                &nbsp; Job Lookup
              </Button>
            </Link> */}
            {/*<Link href="/installerPunch">*/}
            {/*  <Button*/}
            {/*    size="lg"*/}
            {/*    style={{ backgroundColor: "#A8560B", borderColor: "#A8560B" }}*/}
            {/*  >*/}
            {/*    <BsClock />*/}
            {/*    &nbsp; Installer Punch*/}
            {/*  </Button>*/}
            {/*</Link>*/}
          </div>
        </Col>
      </Row>
    </>
  );
}
